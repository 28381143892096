<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-row no-gutters>
                        <TableOfContents
                            :page-key="tableOfContentsPageKey"
                            :table-of-contents="TableOfContents"
                            @load-page="loadPage"
                        ></TableOfContents>
                        <HelpPage
                            :bread-crumb="helpPageBreadCrumb"
                            :help-page-id="helpPageId"
                            :page-key="helpPageKey"
                            @update-done="helpPageUpdated"
                        ></HelpPage>
                    </v-row>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { log } from 'Helpers/helpers';

import TableOfContents from "Components/Appic/Help/TableOfContents";
import HelpPage from "Components/Appic/Help/HelpPage";
import { mapActions } from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
// store
import { store } from '../../../store/store';

export default {
    name: "HelpManual",
    components: {HelpPage, TableOfContents},
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(to.query.page != null) {
                vm.helpPageId = to.query.page
                store.state.appic.help.selectedPage = to.query.page - 10
                console.log(store.state.appic.help.selectedPage)
            }
        })
    },
    data () {
        return {
            helpPageBreadCrumb: [],
            helpPageId: 0,
            helpPageKey: 0,
            loader: false,
            pageKey: 0,
            tableOfContentsPageKey: 0
        }
    },
    computed: {
        statePrefix() {
            return 'current'
        },
        ...mapFieldsPrefixed('help', {
            TableOfContents: 'TableOfContents'
        },'statePrefix'),
        ...mapFields('help',{
            selectedPage: 'selectedPage'
        })
    },
    methods: {
        ...mapActions('help',{
            getAdminTableOfContents: 'getAdminTableOfContents',
            getUserTableOfContents: 'getUserTableOfContents'
        }),
        helpPageUpdated () {
            this.pageKey = Math.floor(Math.random() * 100)
            if(this.$can('update','HelpPage')){
                this.getAdminTableOfContents()
            } else {
                this.getUserTableOfContents()
            }
        },
        loadPage (helpPageId) {
            this.helpPageBreadCrumb = []
            this.helpPageBreadCrumb.push(this.$t('message.appicManual'))
            let tableOfContent = this.TableOfContents.find(t => t.TableOfContent.id == helpPageId)
            if(tableOfContent && tableOfContent.TableOfContent.group.length > 0){
                tableOfContent.TableOfContent.group.forEach(t => {
                    this.helpPageBreadCrumb.push(t)
                })
            }
            this.helpPageBreadCrumb.push(tableOfContent.TableOfContent.title)
            this.helpPageId = helpPageId
            this.helpPageKey = Math.floor(Math.random() * 100)
        }
    },
    mounted() {
        this.selectedPage = null
        // this.getAdminTableOfContents()
        if(this.TableOfContents.length == 0){
            if(this.$can('update','HelpPage')){
                this.getAdminTableOfContents()
            } else {
                this.getUserTableOfContents()
            }
        }
    },
}
</script>

<style scoped>

</style>