<template>
    <div :key="pageKey" class="col-lg-10 col-xs-8 pl-3 pb-0">
        <v-overlay
            :value="loader"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <div v-if="helpPageId == 0">
            <img :src="'/static/img/appic/help/user_manual_cover_20220503.jpg'" class="border-info" :style="'height:' + pageHeight + 'px !important;'"/>
        </div>
        <div class="d-flex flex-row align-center">
            <span class="success--text font-weight-bold">{{ breadCrumb.filter(Boolean).join(" > ") }}</span>
            <v-btn class="ml-3" small v-if="$can('update','HelpPage') && updateMode == false && helpPageId != 0" @click="enterUpdateMode()">{{ $t('message.editContent') }}</v-btn>
            <v-btn class="ml-3" small v-if="$can('update','HelpPage') && updateMode == false && helpPageId != 0" @click="openEditHelpPageSetupDialog()">{{ $t('message.updatePageSetup') }}</v-btn>
        </div>
        <div v-html="purify(HelpPage__html)" v-if="updateMode == false && helpPageId != 0" :style="'height:' + (pageHeight - 40) + 'px !important;'" class="overflow-y-auto overflow-x-hidden mt-3"></div>
        <editor
            :api-key="tinyMce.key"
            :images_upload_url="''"
            :init="{
                content_style: '@import url(\'https://fonts.googleapis.com/css2?family=Roboto&display=swap\');',
                font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Roboto=roboto; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                height: editorHeight,
                plugins: 'print preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                menubar: 'file edit view insert format tools table tc help',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                images_upload_handler: uploadHelpPageImageHandler,
                importcss_append: true,
                setup: function(editor){
                    editor.on('change',() => {
                        updateContent(editor.getContent())
                    })
                    editor.on('keydown', function(event) {
                        if (event.keyCode == 9) { // tab pressed
                            editor.execCommand('mceInsertContent', false, '&emsp;&emsp;'); // inserts tab
                            event.preventDefault();
                            return false;
                        }
                        if (event.keyCode == 32) { // space bar
                            if (event.shiftKey) {
                                editor.execCommand('mceInsertContent', false, '&hairsp;'); // inserts small space
                                event.preventDefault();
                                return false;
                            }
                        }
                    });
                }
            }"
            :value="HelpPage__html"
            ref="helpPageEditor"
            v-if="updateMode == true"
        ></editor>
        <div class="d-flex flex-row mt-3">
            <v-btn small v-if="$can('update','HelpPage') && updateMode == true" @click="exitUpdateMode()">{{ $t('message.dismiss') }}</v-btn>
            <v-btn small class="ml-2" :loading="loading.save" v-if="$can('update','HelpPage') && updateMode == true" @click="saveHelpPage()">{{ $t('message.save') }}</v-btn>
        </div>
        <HelpPageSetup
            :dialog.sync="editDialog"
            :help-page-id="HelpPage__id"
            :help-page-title="HelpPage__title"
            :page-key="dialogPageKey"
            :update-mode="true"
            @dialog-closed="editDialogClosed"
            @update-done="setupUpdated"
        ></HelpPageSetup>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import S3 from 'aws-s3-pro';
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {log, purify} from "Helpers/helpers";
// import {api} from "Api";
const HelpPageSetup = () => import("Components/Appic/Help/HelpPageSetup");

export default {
    name: "HelpPage",
    props: ['helpPageId','pageKey','breadCrumb'],
    components: {
        HelpPageSetup,
        'editor': Editor
    },
    data() {
        return {
            editDialog: null,
            editorHeight: 500,
            pageHeight: 500,
            helpPageContent: null,
            loader: false,
            loading: {
                save: false
            },
            updateMode: false
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('help',{
            HelpPage__id: 'HelpPage.id',
            HelpPage__title: 'HelpPage.title',
            HelpPage__html: 'HelpPage.html'
        },'statePrefix'),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('user',{
            tinyMce : 'tinyMce',
            s3Keys : 's3Keys'
        }),
        // ...mapGetters('user',{
        //     s3Keys : 's3Keys'
        // }),
        baseURL(){
            return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
        },
        config() {
            return {
                bucketName: 'apptimber-appic',
                // dirName: 'help-images',
                region: 'ap-southeast-1',
                accessKeyId: this.s3Keys.id,
                secretAccessKey: this.s3Keys.secret,
                s3Url: this.baseURL
            }
        },
        dialogPageKey() {
            return Math.floor(Math.random() * 100)
        },
        S3Client(){
            return new S3(this.config);
        }
    },
    methods: {
        ...mapActions('help', {
            getHelpPageById: 'getHelpPageById',
            resetCurrentState: 'resetCurrentState',
            updateHelpPage: 'updateHelpPage'
        }),
        log,
        editDialogClosed () {
            this.editDialog = false
        },
        enterUpdateMode () {
            this.updateMode = true
        },
        exitUpdateMode () {
            this.updateMode = false
        },
        handleResize() {
            this.editorHeight = window.innerHeight - (165);
            this.pageHeight = window.innerHeight - (110);
        },
        newFileName(fileName){
            return `${fileName.split('.').slice(0, -1).join()}-${Math.random().toString().slice(2, 5)}`
        },
        openEditHelpPageSetupDialog () {
            this.editDialog = true
        },
        purify,
        saveHelpPage () {
            this.loading.save = true
            this.updateHelpPage()
                .then(() => {
                    this.loading.save = false
                    this.$emit('update-done')
                })
        },
        setupUpdated () {
            this.$emit('update-done')
        },
        updateContent (content) {
            this.HelpPage__html = content
        },
        uploadHelpPageImageHandler (blobInfo, success, failure, progress) {
            let fileName = this.newFileName(blobInfo.name())
            let file = new File([blobInfo.blob()],fileName)
            this.S3Client
                .uploadFile(file, fileName)
                .then((response) => {
                    if (response.status == 204) {
                        success(response.location)
                    } else {
                        failure(response)
                    }
                })
                .catch((e) => {
                    failure(e)
                })
        }
    },
    watch: {
        helpPageId (val) {
            if(val > 0) {
                this.updateMode = false
                this.loader = true
                this.resetCurrentState()
                this.getHelpPageById(val)
                    .then(() => {
                        this.loader = false
                    })
            }
        }
    },
    created() {
        this.updateMode = false
        if(this.helpPageId != 0) {
            this.getHelpPageById(this.helpPageId)
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.resetCurrentState()
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

</style>