<template>
    <div class="col-lg-2 col-xs-4">
        <div :style="'height: ' + sideBarHeight + 'px !important; overflow-y: auto'">
            <v-list dense id="tableOfContents">
                <v-list-item-group v-model="selectedPage">
                    <template v-for="(group, groupKey) in groupedTableOfContents">
                        <template v-if="'title' in group">
                            <v-list-item
                                :key="groupKey"
                                active-class="success lighten-5"
                                ripple
                                @click="loadPage(group.id)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold">{{ group.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item :key="groupKey" inactive active-class="success lighten-2">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold">{{ groupKey }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <template v-for="page in group">
                                <template v-if="'title' in page">
                                    <v-list-item
                                        :key="group.id"
                                        active-class="success lighten-5"
                                        ripple
                                        @click="loadPage(page.id)"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="ml-4">{{ page.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-else>
                                    <v-list-item inactive>
                                        <v-list-item-content>
                                            <v-list-item-title class="ml-4 font-weight-bold">{{ Object.keys(page)[0] }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <template v-for="subPage in page[Object.keys(page)[0]] ">
                                        <v-list-item
                                            :key="subPage.id"
                                            active-class="success lighten-5"
                                            class="ml-4"
                                            ripple
                                            @click="loadPage(subPage.id)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title class="ml-4">{{ subPage.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                </v-list-item-group>
            </v-list>
            <div class="col-12 d-flex flex-column">
                <v-btn color="success" @click="downloadManual()"><v-icon class="white--text mr-2">download</v-icon>{{ $t('message.downloadManual') }}</v-btn>
                <v-btn class="mt-3" v-if="$can('update','HelpPage') && false" @click="openEditTableOfContentsDialog()"><v-icon>edit</v-icon>{{ $t('message.updateTableOfContents') }}</v-btn>
            </div>
        </div>
        <UpdateTableOfContents
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            :page-key="0"
            @dialog-closed="editDialogClosed"
            @update-done="tableOfContentsUpdated"
        ></UpdateTableOfContents>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {log} from "Helpers/helpers";
import {mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {api} from "Api";
import {mapFields} from "vuex-map-fields";
const UpdateTableOfContents = () => import("Components/Appic/Help/UpdateTableOfContents");

export default {
    name: "TableOfContents",
    components: {UpdateTableOfContents},
    props: ['tableOfContents'],
    data() {
        return {
            editDialog: false,
            sideBarHeight: 500,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('help', {
            selectedPage: 'selectedPage'
        }),
        groupedTableOfContents () {
            let pages = {}
            let sortKeys = []
            this.tableOfContents.forEach( page => {
                if(page.TableOfContent.group.length == 0){
                    pages[page.TableOfContent.title] = {
                        id: page.TableOfContent.id,
                        title: page.TableOfContent.title,
                        sortId: page.TableOfContent.sort_id
                    }
                } else if (page.TableOfContent.group.length == 1){
                    if(page.TableOfContent.group[0] in pages == false){
                        pages[page.TableOfContent.group[0]] = []
                    }
                    pages[page.TableOfContent.group[0]].push({
                        id: page.TableOfContent.id,
                        title: page.TableOfContent.title,
                        sortId: page.TableOfContent.sort_id
                    })
                } else if (page.TableOfContent.group.length == 2) {
                    if(page.TableOfContent.group[0] in pages == false){
                        pages[page.TableOfContent.group[0]] = []
                    }
                    let check = pages[page.TableOfContent.group[0]].filter(t => page.TableOfContent.group[1] in t)
                    if(check == false){
                        pages[page.TableOfContent.group[0]].push({
                            [page.TableOfContent.group[1]]: []
                        })
                    }
                    let idx = pages[page.TableOfContent.group[0]].findIndex(t => {
                        return Object.keys(t)[0] == page.TableOfContent.group[1]
                    })
                    sortKeys.push([page.TableOfContent.group[0],idx,page.TableOfContent.group[1]].join('|'))
                    pages[page.TableOfContent.group[0]][idx][page.TableOfContent.group[1]].push({
                        id: page.TableOfContent.id,
                        title: page.TableOfContent.title,
                        sortId: page.TableOfContent.sort_id
                    })
                }
            })
            //do some sorting
            sortKeys.forEach( sortKey => {
                let sortKeyArray = sortKey.split('|')
                pages[sortKeyArray[0]][sortKeyArray[1]][sortKeyArray[2]].sort((a,b)=>{
                    if(a.sortId > b.sortId) return 1
                    if(a.sortId < b.sortId) return -1
                    return 0
                })
            })
            return pages
        }
    },
    methods: {
        ...mapActions('user', {
            resetState: 'resetState'
        }),
        async downloadManual () {
            let manualFileExtension = '.pdf'
            let manualFileType = 'application/pdf'
            let manualFileName = 'APPiC Help Manual'
            try {
                this.$toast(this.$t('message.successes.downloadManualMoment'), {
                    classes: ['text--white'],
                    color: 'info',
                    // timeout: 10000,
                    icon: 'check',
                    iconColor: 'white',
                    timeout: 0,
                    x: 'center',
                    y: 'top'
                })
                let exportedFile = await api.post('/admin/help-pages/download', {
                    pages: this.tableOfContents,
                }, {
                    responseType: 'blob'
                })
                const url = window.URL.createObjectURL(new Blob([exportedFile.data], {type: manualFileType}));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', manualFileName + manualFileExtension);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                //close notification
                let cmp = this.$toast.getCmp()
                cmp.close()
            } catch (error) {
                this.$toast.error(this.$t('message.errors.couldNotDownloadManual') + ": " + error, {
                    timeout: 7000,
                    x: 'center',
                    y: 'top'
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            this.$emit('update-dialog-closed')
        },
        handleResize() {
            this.sideBarHeight = window.innerHeight - (95);
        },
        loadPage (helpPageId) {
            this.$emit('load-page', helpPageId)
        },
        openEditTableOfContentsDialog () {
            this.updateMode = true
            this.editDialog = true
        },
        scrollToSelectedTableOfContent () {
            if(this.selectedPage != null) {
                let scrollToIndex = this.selectedPage
                if(scrollToIndex < 11){
                    scrollToIndex = scrollToIndex - 10
                }
                let scrollToElement = document.querySelector("#tableOfContents div.v-list-item:nth-child(" + scrollToIndex + ")")
                if (scrollToElement) {
                    scrollToElement.scrollIntoView({behavior: "smooth"})
                }
            }
        },
        tableOfContentsUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        this.scrollToSelectedTableOfContent()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 32px;
}
.v-snack__content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
</style>