var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.pageKey,staticClass:"col-lg-10 col-xs-8 pl-3 pb-0"},[_c('v-overlay',{attrs:{"value":_vm.loader,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),(_vm.helpPageId == 0)?_c('div',[_c('img',{staticClass:"border-info",style:('height:' + _vm.pageHeight + 'px !important;'),attrs:{"src":'/static/img/appic/help/user_manual_cover_20220503.jpg'}})]):_vm._e(),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',{staticClass:"success--text font-weight-bold"},[_vm._v(_vm._s(_vm.breadCrumb.filter(Boolean).join(" > ")))]),(_vm.$can('update','HelpPage') && _vm.updateMode == false && _vm.helpPageId != 0)?_c('v-btn',{staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.enterUpdateMode()}}},[_vm._v(_vm._s(_vm.$t('message.editContent')))]):_vm._e(),(_vm.$can('update','HelpPage') && _vm.updateMode == false && _vm.helpPageId != 0)?_c('v-btn',{staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.openEditHelpPageSetupDialog()}}},[_vm._v(_vm._s(_vm.$t('message.updatePageSetup')))]):_vm._e()],1),(_vm.updateMode == false && _vm.helpPageId != 0)?_c('div',{staticClass:"overflow-y-auto overflow-x-hidden mt-3",style:('height:' + (_vm.pageHeight - 40) + 'px !important;'),domProps:{"innerHTML":_vm._s(_vm.purify(_vm.HelpPage__html))}}):_vm._e(),(_vm.updateMode == true)?_c('editor',{ref:"helpPageEditor",attrs:{"api-key":_vm.tinyMce.key,"images_upload_url":'',"init":{
            content_style: '@import url(\'https://fonts.googleapis.com/css2?family=Roboto&display=swap\');',
            font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Roboto=roboto; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            height: _vm.editorHeight,
            plugins: 'print preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            menubar: 'file edit view insert format tools table tc help',
            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,
            images_upload_handler: _vm.uploadHelpPageImageHandler,
            importcss_append: true,
            setup: function(editor){
                editor.on('change',function () {
                    _vm.updateContent(editor.getContent())
                })
                editor.on('keydown', function(event) {
                    if (event.keyCode == 9) { // tab pressed
                        editor.execCommand('mceInsertContent', false, '&emsp;&emsp;'); // inserts tab
                        event.preventDefault();
                        return false;
                    }
                    if (event.keyCode == 32) { // space bar
                        if (event.shiftKey) {
                            editor.execCommand('mceInsertContent', false, '&hairsp;'); // inserts small space
                            event.preventDefault();
                            return false;
                        }
                    }
                });
            }
        },"value":_vm.HelpPage__html}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row mt-3"},[(_vm.$can('update','HelpPage') && _vm.updateMode == true)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.exitUpdateMode()}}},[_vm._v(_vm._s(_vm.$t('message.dismiss')))]):_vm._e(),(_vm.$can('update','HelpPage') && _vm.updateMode == true)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","loading":_vm.loading.save},on:{"click":function($event){return _vm.saveHelpPage()}}},[_vm._v(_vm._s(_vm.$t('message.save')))]):_vm._e()],1),_c('HelpPageSetup',{attrs:{"dialog":_vm.editDialog,"help-page-id":_vm.HelpPage__id,"help-page-title":_vm.HelpPage__title,"page-key":_vm.dialogPageKey,"update-mode":true},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.setupUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }